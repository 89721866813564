<!--
 * @Author: your name
 * @Date: 2020-12-18 13:47:12
 * @LastEditTime: 2020-12-27 23:54:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tostumid:\project\web\sxvant\src\views\Home.vue
-->
<template>
  <div class="home">
    <h1>伴学机器人产品手册</h1>
    <div class="title">
      <h3>认识你手上的这台设备</h3>
      <h4>KNOW YOUR SRWAY</h4>
    </div>
    <p>
      伴学机器人SRWAY是新一代的智能教育学习终端。由学习机+影柱组成，支持文稿人像高清双摄、一键投屏多显示设备、手写笔写灵活切换、高清护眼大屏等功能。搭载丰富的教学资源库，适用于在线教育、在线会议和移动办公等应用场景。
    </p>
    <div class="title">
      <h3>外观介绍：</h3>
    </div>
    <img src="@/assets/cp1.png" />
    <img src="@/assets/cp2.png" />
    <img src="@/assets/cp3.png" class="img3" />
    <div class="tabel">
      <van-row>
        <van-col span="8" v-for="(item, index) in tabel" v-bind:key="index">
          <span>{{ index + 1 }}</span
          >{{ item }}
        </van-col>
      </van-row>
    </div>

    <hr class="hr2" />
    <div class="title">
      <h3>装箱清单</h3>
      <h4>PACKING LIST</h4>
    </div>

    <div class="tabel2">
      <table
        style="table-layout: fixed;"
        border="1"
        width="100%"
        cellspacing="0"
        cellpadding="2"
      >
        <tbody>
          <tr>
            <th style="width:15%">序号</th>
            <th style="width:55%">名称</th>
            <th style="width:15%">数量</th>
            <th style="width:15%">单位</th>
          </tr>
          <tr v-for="(item, index) in tabel2" v-bind:key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.num }}</td>
            <td>{{ item.type }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr class="hr2" />
    <div class="title">
      <h3>快速上手</h3>
      <h4>GET STARTED QUICKLY</h4>
    </div>

    <div class="info">
      <li>开机和关机</li>
      <p>
        首先，要使用本设备的各项功能，设备必须连接电源适配器到220V供电插座，连接电源后，电源指示灯亮红灯。
      </p>
      <p>
        1、开机：按住开/关机键，电源指示灯变为绿灯，松开按钮，即可正常开机。
      </p>
      <p>
        2、关机：按住开/关机键，电源指示灯变为红灯，松开按钮，即可正常关机。
      </p>

      <hr />

      <li>网络访问</li>
      <p>
        1、有线网络：将通讯正常的网线端口插入设备后侧的网口，无需设置，设备可自动联网。
      </p>
      <p>
        2、无线网络：打开系统设置，开启WLAN，搜索热点，输入正确的密钥，即可联网成功。
      </p>
      <p>
        网络状态会分别显示在导航栏，如果无线或有线网络的图标点亮，则可以正常访问网络了。
      </p>

      <hr />

      <li>共享投屏</li>
      <p>
        1、有线投屏：将多媒体连接线一端插入设备后侧的HDMI接口，另一端连接电视、大屏幕、电脑等显示设备，即可实现设备内容上大屏。
      </p>
      <p>
        2、无线投屏：保证学习机与显示设备在同一个局域网下，下拉屏幕导航栏，点击无线投屏功能，选择要投屏的显示设备名称，即可实现一键投屏。
      </p>

      <hr />

      <li>使用摄像头</li>
      <p>
        1、主摄像头：在屏幕上方，您可以旋转摄像头0°~90°范围内到合适的角度拍摄。
      </p>
      <p>
        2、扫描摄像头：将影柱插头插入学习机侧面的影柱插销，您可以展开影柱，扫描文件或物体。
      </p>
      <hr />

      <li>使用USB</li>
      <p>USB口可以插入U盘、打印机、鼠标、键盘等外设，使用扩展的外设功能。</p>
      <hr />

      <li>使用耳机</li>
      <p>
        使用Type-C接口连接耳机，您可以使用转换器连接3.5mm母头耳机或直接使用Type-C接口耳机。
      </p>
      <hr />

      <li>使用 Pen</li>
      <p>从学习机后侧笔槽中取出笔，您即可在屏幕上随意书写。</p>
      <hr />

      <li>使用TF存储卡</li>
      <p>
        将存储卡插入TF卡槽中，开机状态下，您就可以通过平板查看或导出存储卡中的内容。
      </p>
      <hr />

      <li>调节音量</li>
      <p>当您在收听音乐、视频或其他媒体时，侧键音量+/-按钮可以调节声音大小。</p>
      <hr />

      <li>使用影柱补光灯</li>
      <p>
        补光灯会随扫描摄像头一同开启，若需要调节补光灯亮度，下拉屏幕导航栏，点击补光灯功能，选择明亮、温和或弱光三档亮度中的其中一种亮度，则影柱补光灯会立即光照出对应的亮度环境。
      </p>
    </div>

    <hr class="hr2" />
    <div class="title">
      <h3>安全须知</h3>
      <h4>SAFETY NOTICE</h4>
    </div>

    <div class="info">
      <p>1、请勿投掷、撞击、针刺本产品。</p>
      <p>
        2、请勿将本产品与锋利的物体放在一起，防止因为摩擦而产生屏幕刮花和外壳的磨损。
      </p>
      <p>
        3、请勿将本产品暴露在高温或严寒下，这样可能会造成产品散热不良或内部温度过高，导致系统运行不稳定甚至停止工作、产品损坏、或爆炸事故发生。
      </p>
      <p>4、请勿将本产品放置于易受潮的地方，存在电击的危险。</p>
      <p>5、禁止使用腐蚀性清洁液或喷雾清洁剂，用柔软湿布擦拭即可。</p>
      <p>
        6、请勿随意打开、分解机壳、使用暴力手段拆卸本产品。如有故障，请联系合格的维修人员进行维修。
      </p>
      <p>7、请勿使用未经生产厂家许可的配件，否则会带来使用风险。</p>
    </div>

    <hr class="hr2" />
    <div class="title">
      <h3>环保标准</h3>
      <h4>SAFETY STANDARD</h4>
    </div>

    <div class="info tabel3">
      <table
        style="table-layout: fixed;"
        border="1"
        width="100%"
        cellspacing="0"
        cellpadding="2"
      >
        <tbody>
          <tr>
            <th style="width:100%" colspan="7">产品中有害物质的名称及含量</th>
          </tr>

          <tr>
            <td style="width:30%" rowspan="2">部件名称</td>
            <td style="width:70%" colspan="6">有害物质</td>
          </tr>
          <tr>
            <td style="width:10%">铅<br />(Pb)</td>
            <td style="width:10%">汞<br />(Hg)</td>
            <td style="width:10%">镉<br />(Cd)</td>
            <td style="width:10%">六价铬<br />(Cr(VI))</td>
            <td style="width:10%">多溴联苯<br />(PBB)</td>
            <td style="width:50%">多溴二苯醚<br />(PBDE)</td>
          </tr>
          <tr v-for="(item, index) in tabel3" v-bind:key="index">
            <td>{{ item.name }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.type }}</td>
          </tr>
          <tr>
            <td colspan="7">
              <p>本表格依据SJ/T 11364的规定编制。</p>
              <p>
                ○：表示该有害物质在该部件所有均质材料中的含量均在GB/T
                26572规定的限量要求以下。
              </p>
              <p>
                ×：表示该有害物质至少在该部件的某一均质材料中的含量超出GB/T
                26572规定的限量要求。但所有部件都符合欧盟RoHS要求。
              </p>
              <p>
                <img
                  src="@/assets/cp4.png"
                />本标识内数字表示产品在正常使用状态下的环保使用期限为10年，环保使用期限内不释放有害物质。
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr class="hr2" />
    <div class="title">
      <h3>法律声明</h3>
      <h4>LEGAL STATEMENT</h4>
    </div>

    <div class="info">
      <p>
        本资料著作权属广州锶想智能科技有限责任公司所有。未经锶想公司书面许可，任何单位或个人不得以任何方式摘录、复制或翻译。
      </p>
      <p>
        对本手册中的印刷错误及与最新资料不符之处我们会及时改进。这些改动不再事先通知，但会编入新版手册中，锶想公司保留最终解释权。
      </p>
      <p>Copyright © 2020 广州锶想智能科技有限责任公司，侵权必究。</p>
    </div>

    <hr class="hr2" />
    <div class="title">
      <h3>产品参数</h3>
      <h4>PRODUCT SPECIFICATION</h4>
    </div>

    <div class="info"><img src="@/assets/cp6.jpg" /></div>

    <hr class="hr2" />
    <div class="title">
      <h3>三包服务政策</h3>
      <h4>THREE GUARANTEES POLICY</h4>
    </div>

    <div class="info">
      <p>
        消费者购买的锶想产品，如果出现非人为损坏的性能故障，享有锶想公司提供的修理、更换、退货（简称“三包”）的服务承诺。
      </p>
      <h4>一、为了保障消费者的合法权益，请消费者关注以下信息</h4>
      <p>
        请消费者妥善保管三包凭证与购机发票。三包有效期自开具发票之日起计算。
      </p>
      <p>在锶想授权服务中心为消费者提供服务时，请消费者出示有效的购机发票。</p>
      <p>
        若消费者丢失发票，维修时需提供发票底联或者发票底联复印件等有效证据，用以证明设备在三包有效期内。
      </p>
      <p>
        若消费者丢失发票，且不能提供发票底联或者发票底联复印件等有效证据的，按照主机机身SN号显示的出厂日期向后顺延90日计算三包有效期的起始日期。
      </p>
      <h4>
        二、适用过程中，如果出现非人为损坏的性能故障，消费者享有如下三包权益
      </h4>
      <p>
        自购机之日7日内，若主机、配件商品出现非人为损坏的性能故障，可以选择退货、换货或者修理。
      </p>
      <p>
        自购机之日起第8日至15日内，若主机、配件商品出现非人为损坏的性能故障，可以选择换货或者修理。
      </p>
      <p>
        质保1年，在三包有效期内，产品经过锶想售后服务中心检测，确认故障在三包责任范围内，根据三包法规定提供包修服务；如果确认故障不在三包责任范围内，用户确定维修的，维修需要收取定额费用。
      </p>
      <h4>三、符合下列情况之一的设备，不实行三包</h4>
      <p>1、超过三包有效期的。</p>
      <p>2、未按产品使用的要求适用、维护、保管而造成损坏的。</p>
      <p>3、非承担三包的修理者拆动造成损坏的。</p>
      <p>
        4、无有效三包凭证及有效发票的（能够证明该商品在三包有效期内的除外）。
      </p>
      <p>5、擅自涂改三包凭证的。</p>
      <p>6、三包凭证上的产品型号或编号与商品实物不相符合的。</p>
      <p>7、使用盗版软件造成损坏的。</p>
      <p>8、使用过程中感染病毒造成损坏的。</p>
      <p>9、无厂名、厂址、生产日期、产品合格证的。</p>
      <p>10、因不可抗力造成损坏的。</p>

      <h4>四、其他说明</h4>
      <p>
        上述各条款仅适用于在中华人民共和国境内（港、澳、台除外）销售的终端。销售者向您做出的非锶想保证的其他承诺，锶想不承担任何责任。
      </p>
      <h4>五、售后服务</h4>
      <p>全国统一售后服务热线：020-80922872</p>
      <p>扫描二维码，获取更多产品技术支持相关信息：</p>
      <div style="text-align: center;">
        <img src="@/assets/cp5.png" style="width:100px" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      tabel: [
        "高清主摄像头",
        "扬声器",
        "10.1寸护眼屏",
        "影柱",
        "开/关机键",
        "音量+/-键",
        "电源指示灯",
        "影柱插销",
        "笔槽",
        "电源口",
        "TF卡槽",
        "HDMI口",
        "网口",
        "USB口*2",
        "Type-C/OTG",
        "超清扫描摄像头",
        "补光灯",
        "影柱插头",
      ],
      tabel2: [
        {
          id: 1,
          name: "学习机+影柱",
          num: 1,
          type: "台",
        },
        {
          id: 2,
          name: "SRWAY Pen",
          num: 1,
          type: "支",
        },
        {
          id: 3,
          name: "电源适配器",
          num: 1,
          type: "个",
        },
        {
          id: 4,
          name: "Type-C音频转换器",
          num: 1,
          type: "台",
        },
      ],
      tabel3: [
        {
          name: "学习机",
          type: "○",
        },
        {
          name: "影柱",
          type: "○",
        },
        {
          name: "电磁笔",
          type: "○",
        },
        {
          name: "电源适配器",
          type: "○",
        },
        {
          name: "转接线",
          type: "○",
        },
      ],
    };
  },
};
</script>

<style scoped>
.home {
  padding: 15px;
  text-align: left;
}
.title {
  margin-bottom: 20px;
}
.tabel {
  font-size: 28px;
  margin-bottom: 30px;
}
.tabel span {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  background: #000;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  margin-right: 5px;
}
.van-col--8 {
  padding-bottom: 10px;
  margin-bottom: 10px;
  height: 32px;
  border-bottom: 1px solid #f0f0f0;
}
.img3 {
  max-width: 500px;
  margin: 0 auto 40px;
}
.tabel2 {
  margin-bottom: 60px;
}
.info {
  padding-bottom: 30px;
}

.info h4 {
  padding-bottom: 20px;
}
.tabel3 {
  margin-bottom: 30px;
}
.tabel3 td {
  font-size: 24px;
}
.tabel3 p {
  font-size: 24px;
  text-align: left;
  color: #333;
  line-height: 1.4;
  text-indent: 0em;
  margin-bottom: 6px;
}
.tabel3 img {
  display: inline-block;
  vertical-align: middle;
  width: 50px;
}
h1,
h3,
h4 {
  font-weight: 500;
  font-family: "微软雅黑";
}
h1 {
  font-size: 40px;
  text-align: center;
  line-height: 3;
  color: #000;
}
h3 {
  font-size: 36px;
  line-height: 2;
  color: #333;
}
h4 {
  font-size: 26px;
  line-height: 2;
}
p {
  font-size: 28px;
  color: #444;
  line-height: 1.6;
  text-indent: 1em;
  margin-bottom: 20px;
}
table {
  table-layout: fixed !important;
  width: 100% !important;
  border-collapse: collapse;
  border: none;
  font-size: 30px;
}
li {
  font-size: 32px;
  line-height: 2;
  color: #333;
  text-indent: 0.5em;
  margin-bottom: 15px;
}
td,
th {
  width: 1px;
  white-space: nowrap; /* 自适应宽度*/
  word-break: keep-all; /* 避免长单词截断，保持全部 */
  border: solid #676767 1px;
  text-align: center;
  white-space: pre-line;
  word-break: break-all !important;
  word-wrap: break-word !important;
  display: table-cell;
  vertical-align: middle !important;
  white-space: normal !important;
  height: auto;
  vertical-align: text-top;
  padding: 5px;
  display: table-cell;
}
td {
  font-size: 28px;
}
hr {
  height: 1px;
  border: none;
  border-top: 1px dashed #0066cc;
  margin: 15px 0;
}
.hr2 {
  border-top: 1px solid #ccc;
  margin: 15px 0 20px;
}
</style>
